<template>
  <div>
    <div class="flex justify-between items-center mt-3 mb-5">
      <div class="flex items-center">
        <t-button class="text-sm mr-2" :disabled="true">
          <i class="fas fa-list"></i>
          {{ $t("overview") }}
        </t-button>
        <t-button
          class="text-sm"
          variant="primary"
          @click="showCreateModal = true"
        >
          <i class="fas fa-plus"></i>
          {{ $t("template_add") }}
        </t-button>
      </div>
      <div class="flex justify-between items-center">
        <div class="flex gap-4 items-center flex-wrap lg:flex-nowrap">
          <div class="flex items-center justify-between w-60">
            <label class="w-30 font-semibold mr-5">{{
              $t("horeca_location")
            }}</label>
            <t-select
              class="w-44"
              v-model="locationFilter"
              :options="locationList"
              value-attribute="id"
              text-attribute="description"
            ></t-select>
          </div>
          <div class="flex items-center justify-between w-60">
            <label class="w-16 font-semibold">{{ $t("job_type") }}</label>
            <t-select
              class="w-44"
              v-model="scenarioFilter"
              :options="scenarioList"
              value-attribute="id"
              text-attribute="name"
            ></t-select>
          </div>
        </div>
      </div>
    </div>
    <div class="overflow-x-auto">
      <t-table class="w-full" :headers="headers" :data="templates">
        <template slot="row" slot-scope="props">
          <tr class="hover:bg-gray-100">
            <td class="pl-3">{{ props.row.name }}</td>
            <td class="pl-3">
              {{ props.row.scenario | get("name") }}
            </td>
            <td class="pl-3">{{ props.row.locations | combine }}</td>
            <td class="pl-3">
              <div class="flex justify-end px-5">
                <t-button
                  class="border-2 text-xs rounded-xs m-2"
                  variant="primary"
                  :disabled="loading"
                  @click="
                    $router.push(`/manager/templates/${props.row.id}/edit`)
                  "
                >
                  <i class="fas fa-pen"></i>
                </t-button>
                <t-button
                  class="border-2 text-xs rounded-xs m-2"
                  variant="danger"
                  @click="deleteTemplate(props.row.id)"
                  :disabled="loading"
                >
                  <i class="fas fa-trash"></i>
                </t-button>
              </div>
            </td>
          </tr>
        </template>
      </t-table>
    </div>
    <t-modal v-model="showCreateModal">
      <div class="mb-4">
        <label class="w-16 font-semibold">{{ $t("name") }}</label>
        <t-input
          class="w-full height-fix"
          :variant="errors.newTemplate ? 'danger' : ''"
          v-model="newTemplate"
        />
      </div>
      <div class="mb-4">
        <label class="w-16 font-semibold">Scenario</label>
        <t-select
          class="w-full"
          v-model="selectedScenario"
          :variant="errors.selectedScenario ? 'danger' : ''"
          :options="scenarios"
          value-attribute="id"
          text-attribute="name"
          placeholder="Select scenario"
        ></t-select>
      </div>
      <template v-slot:footer>
        <div class="flex justify-end">
          <t-button
            type="button"
            class="text-sm mr-2"
            variant="primary"
            :disabled="loading"
            @click="createTemplate"
          >
            {{ $t("choose") }}
          </t-button>
          <t-button
            type="button"
            class="text-sm"
            :disabled="loading"
            @click="showCreateModal = false"
            >{{ $t("close") }}</t-button
          >
        </div>
      </template>
    </t-modal>
  </div>
</template>

<script>
import { actions, getters } from "@/constants/state";
import { create } from "@/services/template-service";
import { get, map } from "lodash";
import { mapActions, mapGetters } from "vuex";

export default {
  data: () => ({
    scenarioFilter: null,
    newTemplate: null,
    selectedScenario: null,
    showCreateModal: false,
    loading: false,
    errors: {
      newTemplate: false,
      selectedScenario: false,
    },
  }),
  methods: {
    ...mapActions({
      setTemplateFilterAction:
        actions.SESSION_SET_TEMPLATE_MANAGER_FILTER_ACTION,
      search: actions.TEMPLATES_SEARCH_ACTION,
      remove: actions.TEMPLATES_REMOVE_ACTION,
    }),
    searchTemplates() {
      this.search({
        locationId: this.locationFilter,
        scenarioId: this.scenarioFilter,
      });
    },
    async createTemplate() {
      this.errors["newTemplate"] = !this.newTemplate;
      this.errors["selectedScenario"] = !this.selectedScenario;

      if (Object.values(this.errors).some((error) => error)) {
        return;
      }

      try {
        this.loading = true;
        const { data } = await create({
          name: this.newTemplate,
          scenario_id: this.selectedScenario,
        });
        this.$router.push(`/manager/templates/${data}/edit`);
      } catch (error) {
        console.error("createTemplate", error);
      }

      this.loading = false;
    },
    async deleteTemplate(id) {
      if (!confirm(this.$t("are_you_sure"))) {
        return;
      }

      try {
        this.loading = true;
        await this.remove(id);
      } catch (error) {
        console.error("deleteTemplate", error);
      }

      this.loading = false;
    },
  },
  computed: {
    ...mapGetters({
      templates: getters.TEMPLATES_SEARCH_GETTER,
      locations: getters.HORECA_LOCATIONS_GETTER,
      scenarios: getters.SCENARIOS_OVERVIEW_GETTER,
      filter: getters.SESSION_TEMPLATE_MANAGER_FILTER_GETTER,
      horecaLocationId: getters.DOMAIN_LOCATION_ID_GETTER,
    }),
    locationList() {
      return [{ description: "All locations" }, ...this.locations];
    },
    scenarioList() {
      return [{ name: "All scenarios" }, ...this.scenarios];
    },
    headers() {
      return [
        {
          value: "name",
          text: this.$i18n.t("name"),
        },
        {
          value: "name",
          text: "Scenario",
        },
        {
          value: "name",
          text: "Horeca Locations",
        },
        {},
      ];
    },
    locationFilter: {
      get() {
        if (this.filter.horecaLocationId === null) {
          return this.horecaLocationId;
        }

        return +this.filter.horecaLocationId;
      },
      set(horecaLocationId) {
        this.setTemplateFilterAction({
          horecaLocationId,
        });
      },
    },
  },
  filters: {
    get(value, key) {
      return get(value, key);
    },
    combine(value) {
      return map(value, "description").join(", ");
    },
  },
  watch: {
    locationFilter: {
      immediate: true,
      handler() {
        this.search({
          locationId: this.locationFilter,
          scenarioId: this.scenarioFilter,
        });
      },
    },
    scenarioFilter: {
      immediate: true,
      handler() {
        this.search({
          locationId: this.locationFilter,
          scenarioId: this.scenarioFilter,
        });
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
